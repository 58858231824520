@tailwind base;
@tailwind components;
@tailwind utilities;

.mention {
  background-color: #e3f2fd;
  border-radius: 0.25rem;
  padding: 0.125rem 0.25rem;
  color: #1e88e5;
  font-weight: 600;
  white-space: nowrap;
  text-decoration: none;
  font-size: 1rem;
}

.thread-reply {
  background-color: #f0f0f0;
  border-left: 4px solid #ccc;
  padding: 8px;
  margin: 4px 0;
}

.mention:hover {
  background-color: #bbdefb;
}

.ProseMirror {
  min-height: inherit;
  height: inherit;
  cursor: text;
}

.ProseMirror p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
  font-size: 0.875rem;
}

.mention-list {
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  overflow: hidden;
  max-height: 20rem;
  overflow-y: auto;
  border: 1px solid #e5e7eb;
}

.speech-bubble-reply {
  position: relative;
  margin-left: 0;
  margin-top: 0;
}

.editor-content {
  font-size: 1rem;
  line-height: 1;
  min-height: inherit;
  height: inherit;
}

.editor-content > * {
  margin-top: 0.5rem;
}

.editor-content > *:first-child {
  margin-top: 0;
}

.editor-content ul {
  list-style-type: disc;
  padding-left: 1.5rem;
  margin: 0.5rem 0;
}

.editor-content ol {
  list-style-type: decimal;
  padding-left: 1.5rem;
  margin: 0.5rem 0;
}

.editor-content li {
  margin: 0.25rem 0;
}

.editor-content li > p {
  margin: 0;
  display: inline;
}

.editor-content a {
  color: #2563eb;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
}

.editor-content blockquote {
  padding-left: 1rem;
  border-left: 2px solid #e5e7eb;
  color: #6b7280;
  font-style: italic;
}

.editor-content code {
  background-color: #f3f4f6;
  padding: 0.2em 0.4em;
  border-radius: 0.25rem;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

.editor-content img {
  max-width: 300px;
  max-height: 200px;
  width: auto;
  height: auto;
  border-radius: 0.5rem;
  margin: 0.5rem 0;
  display: block;
  object-fit: contain;
}

/* Image viewer animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.95);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.image-viewer-backdrop {
  animation: fadeIn 0.2s ease-out;
}

.image-viewer-content {
  animation: scaleIn 0.3s ease-out;
}

.image-viewer-toolbar {
  animation: slideUp 0.3s ease-out;
}

/* Image grid hover effects */
.image-grid-item {
  transition: all 0.3s ease;
}

.image-grid-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.1);
}

.image-grid-item img {
  transition: transform 0.5s ease;
}

.image-grid-item:hover img {
  transform: scale(1.05);
}

.image-grid-overlay {
  opacity: 0;
  transition: opacity 0.3s ease;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, transparent 100%);
}

.image-grid-item:hover .image-grid-overlay {
  opacity: 1;
}

.image-grid-actions {
  transform: translateY(10px);
  opacity: 0;
  transition: all 0.3s ease;
}

.image-grid-item:hover .image-grid-actions {
  transform: translateY(0);
  opacity: 1;
}

/* Emoji picker styles */
.EmojiPickerReact {
  --epr-hover-bg-color: #f3f4f6 !important;
  --epr-focus-bg-color: #f3f4f6 !important;
  --epr-highlight-color: #2563eb !important;
  --epr-search-border-color: #e5e7eb !important;
  --epr-category-label-bg-color: #ffffff !important;
}

/* Custom scrollbar */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

/* Loading spinner */
.loading-spinner {
  border: 3px solid rgba(59, 130, 246, 0.1);
  border-top-color: #3b82f6;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

#pdf-controls {
  display: none;
}

#pdf-page-wrapper {
  margin: 0;
}

@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root,
.app,
.content {
  height: auto;
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
}

:root {
  --ajusteBat: rgb(153, 117, 108);
  --pink: rgb(251, 180, 244);
  --violetDark: rgb(102, 69, 169);
  --orange: rgb(253, 188, 100);
  --skyBlue: rgb(134, 180, 202);
  --greenYellow: rgb(51, 211, 145);
  --violet: rgb(147, 111, 218);
  --blue: rgb(43, 118, 229);
  --greenDark: rgb(23, 90, 99);
  --blackLow: rgb(92, 92, 92);
  --orangeElectric: rgb(255, 131, 88);
  --greenGrass: rgb(53, 153, 112);
  --orangeLow: rgb(253, 171, 61);
  --pinkGlow: rgb(217, 116, 176);
  --blueOcean: rgb(161, 227, 246);
  --uva: rgb(152, 98, 161);
  --mustard: rgb(255, 213, 51);
  --cafe: rgb(173, 150, 122);
  --blueTwo: rgb(121, 175, 253);
  --blueOceanTwo: rgb(133, 214, 255);
  --greenHoja: rgb(176, 220, 81);
  --mustardTwo: rgb(213, 197, 103);
  --lila: rgb(189, 168, 249);
  --greenGrassTwo: rgb(53, 153, 112);
  --issues: rgb(232, 105, 125);
  --done: rgb(51, 211, 145);
  --paused: rgb(255, 213, 51);
  --aqua: rgb(113, 214, 209);
  --salmon: rgb(255, 145, 145);
  --pinkTwo: rgb(255, 123, 208);
  --violetThree: rgb(181, 125, 227);
  --pinkThree: rgb(255, 21, 138);
  --red: rgb(201, 92, 118);
  --blueThree: rgb(51, 158, 205);
  --blueFour: rgb(53, 135, 250);

  --salmonTwo: rgb(255, 189, 189);
  --gray: rgb(153, 153, 153);
  --grayDark: rgb(95, 94, 94);

  --grayTwo: rgb(189, 187, 187);
  --brown: rgb(127, 83, 71);
  --blueLow: #f8f7ff;
  --blueLowTwo: #e4e6eb;
  --blueLowThree: #e4e6eb;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #e0e0e0;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

ul.MuiList-root {
  padding: 18px 24px;
}

.pixel-column--cell {
  background-color: blue;
}

li.MuiButtonBase-root {
  margin: 6px;
  /* color: white; */
  width: 220px;
}

/* li.MuiButtonBase-root:nth-child(1) {
  background-color: var(--pink);
}
span[value^="PIXEL INTEGRATION"] {
  background-color: #ffff00;
}
li.MuiButtonBase-root:nth-child(2) {
  background-color: var(--violetDark);
}
li.MuiButtonBase-root:nth-child(3) {
  background-color: var(--orange);
}
li.MuiButtonBase-root:nth-child(4) {
  background-color: var(--skyBlue);
}
li.MuiButtonBase-root:nth-child(5) {
  background-color: var(--greenYellow);
}
li.MuiButtonBase-root:nth-child(6) {
  background-color: var(--violet);
}
li.MuiButtonBase-root:nth-child(7) {
  background-color: var(--blue);
}
li.MuiButtonBase-root:nth-child(8) {
  background-color: var(--greenDark);
}
li.MuiButtonBase-root:nth-child(9) {
  background-color: var(--blackLow);
}
li.MuiButtonBase-root:nth-child(10) {
  background-color: var(--orangeElectric);
}
li.MuiButtonBase-root:nth-child(11) {
  background-color: var(--greenGrass);
}
li.MuiButtonBase-root:nth-child(12) {
  background-color: var(--orangeLow);
} */
#status {
  padding: 0;
  width: 140px;
}

#menu-item {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /* height: 320px;
  width: 500px; */
}
#item {
  display: flex;
}

.css-1qu5r5x .MuiInputBase-colorPrimary {
  outline: none;
  border: none;
}

/* .MuiOutlinedInput-notchedOutline {
  display: none;
} */

.container-cell-data {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cell-data {
  background-color: var(--blueLow);
  color: var(--blueFour);
  padding: 2px 8px;
  border-radius: 25px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.css-2iz72e .MuiSvgIcon-root {
  color: currentColor;
}

.MuiTextField-root {
  border-color: black;
}

.newthread:hover {
  background-color: red;
}

.select-menu {
  display: flex;
}

th {
  border-right: 1px solid black;
  padding: 10px 25px;
}

td {
  border-right: 1px solid black;
}

th:last-child {
  border-right: 0;
}

.paragraph {
  width: 100%;
  padding: 0 10px;
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cellStyle {
  cursor: pointer;
  position: relative;
  word-wrap: break-word;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  min-width: 80px;
  min-height: 30px;
  cursor: "pointer";
}

.modalStyle {
  padding: 10px;
  display: flex;
  justify-content: center;
  height: auto;
  position: absolute;
  transform: translate(-36%);
  overflow: auto;
}

.box {
  min-height: 150px;
  width: 100%;
  transition: all 0.5s ease;
}

.box.open {
  opacity: 1;
  background-image: none;
  transition: "all 0.5s ease";
}

.closed {
  display: none;
}

.ssky-mention-input {
  position: relative;
  border-radius: 4px;
  border: 1px solid #bdbdbd;
  transition: all 0.3s;
  height: 400px;
  z-index: 10;
}

.ssky-mention-input__highlighter {
  height: 400px;
}

.ssky-mention-label {
  position: absolute;
  z-index: 1000;
  left: 10px;
  top: 10px;
  pointer-events: none;
  transition: 0.2s ease all;
  opacity: 0.5;
}

.ssky-mention-input:focus + .ssky-mention-label,
.ssky-mention-input:not(:placeholder-shown) + .ssky-mention-label {
  transform: translateY(-24px);
  font-size: 12px;
  opacity: 1;
}

/*
.ssky-mention-input:focus-within {
  border-color: #1976d2;
  box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.5);
}

.ssky-mention-input input {
  padding: 10px;
  font-size: 16px;
  border: none;
  outline: none;
  width: 100%;
  box-sizing: border-box;
}

.ssky-mention-input label {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 16px;
  color: #757575;
  transition: all 0.3s;
}
.ssky-mention-input input:focus + label,
.ssky-mention-input input:not(:placeholder-shown) + label {
  top: 0;
  font-size: 12px;
  color: #1976d2;
  transform: translateY(-6px);
} */

/* .ssky-mention-input__control {
  font-size: 16px;
}

.ssky-mention-input__input {
  border: 0;
  border-bottom: 1px solid red;
  padding: 21px 0 3px 0;
} */

div.input-block {
  position: relative;
  width: 400px;
}
div.input-block textarea {
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
  line-height: 1.4375em;
  padding: 16.5px 14px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  outline: none;
  min-height: 171px;
  height: auto;
  overflow: hidden;
  resize: none;
  overflow: hidden;
  letter-spacing: -0.2px;
  font-family: Source Sans Pro, sans-serif;
  background-color: transparent;
}
div.input-block span.placeholder {
  position: absolute;
  margin: 15px 0;
  padding: 0 6px;
  color: #6c757d;
  display: flex;
  align-items: center;
  font-size: 15px;
  top: 0;
  left: 10px;
  transition: all 0.2s;
  transform-origin: 0% 0%;
  background: none;
  pointer-events: none;
}
div.input-block textarea:valid + span.placeholder,
div.input-block textarea:focus + span.placeholder {
  transform: scale(0.8) translateY(-29px);
  background: #fcfaff;
}
div.input-block textarea:focus {
  color: black;
  border-color: black;
}
div.input-block textarea:hover {
  color: black;
  border-color: black;
}
div.input-block textarea:focus + span.placeholder {
  color: black;
}

.selected {
  background-color: red;
}
.selected-value {
  background-color: lightblue;
}

.autocomplete-list {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  width: 400px;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 3px 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 0;
  margin: 0;
  list-style: none;
}

.autocomplete-list li {
  padding: 5px;
  cursor: pointer;
}

.autocomplete-list li:hover,
.autocomplete-list li:focus {
  background-color: #f2f2f2;
}

.autocomplete-list li.selected {
  background-color: #f2f2f2;
}

.autocomplete-list li:last-of-type {
  border-radius: 0 0 3px 3px;
}

/* .selected-item {
  background-color: #c5eff7;
  border-radius: 2px;
  padding: 2px;
} */

/* .red-bg {
  background-color: red;
}

.selected-item {
  background-color: red;
} */

.ql-mention-list-container {
  background-color: #fcfaff;
  max-height: 441px;
  max-width: 1006.5px;
  min-width: 380px;
  top: 100%;
  box-shadow: rgb(101 119 134 / 20%) 0px 0px 15px,
    rgb(101 119 134 / 15%) 0px 0px 3px 1px;
  margin-top: 15px;
  margin-left: -15px;
  border: 1px solid #dee2ff;
  z-index: 1000;
  overflow: scroll;
  overflow-x: hidden;
}

.ql-mention-list::after {
  content: "";
  position: absolute;
  left: 10px;
  top: -12px;
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 12px solid #dee2ff;
}

.ql-mention-list-container::after {
  content: "";
  position: absolute;
  left: 11px;
  top: -11px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 11px solid #fcfaff;
}

.ql-editor {
  min-height: 100px;
}

.ql-mention-list-item {
  height: 60px;
  padding: 20px;
}

.ql-toolbar {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.ql-toolbar.ql-snow {
  padding: 16.5px 14px;
}

.ql-container {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

/* .mention {
  color: var(--blueTwo);
  cursor: pointer;
} */

.selected {
  background-color: var(--blueLow);
}

.ql-mention-list {
  list-style: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.ql-snow .ql-editor *:focus {
  outline: none;
  background-color: transparent;
}

.seen-container {
  z-index: 1000;
  border-radius: 10px;
  width: 200px;
  min-height: 50px;
  background-color: var(--blackLow);
  padding: 12px 8px 12px 12px;
  color: #fcfaff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: 0.2s ease-in-out; /* add transition for smooth animation */
}

.seen-container::before {
  content: "";
  position: absolute;
  left: 95px;
  bottom: -10px;
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 12px solid var(--blackLow);
  transform: rotate(180deg);
}

.user-seen {
  object-fit: cover;
  object-position: center;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 1px solid #888;
}

.seen-by-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(30px, auto); /* Set minimum and maximum row height */
  grid-gap: 2px; /* Add some gap between grid items */
}

/* add this rule */
.seen-container:hover {
  transform: translateY(calc(-100% - 10px)); /* adjust the value as needed */
}

.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  z-index: 1500;
}

.MuiModal-root {
  z-index: 1000;
}

.rdrDateRangePickerWrapper {
  max-width: 60%;
}

#header-bar {
  display: none;
}

/* .MuiTabs-flexContainer {
  justify-content: center;
} */

.MuiDataGrid-columnHeader:first-child {
  position: absolute;
  left: 0;
}

#image-renderer {
  background-image: none;
  background-color: #fcfaff;
  max-height: 700px;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  font-size: 1.25em;
  font-weight: 700;
  color: white;
  background-color: black;
  display: inline-block;
  cursor: pointer;
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-color: red;
}

.dropArea {
  border: 2px dashed var(--blueFour);
  height: 50px;
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.no-scroll {
  overflow-x: hidden;
}

.highlighted-row {
  background-color: var(--blueLowTwo);
  z-index: 1000;
  font-weight: 600;
}

.total-row {
  background-color: var(--blackLow);
  color: white;
  z-index: 1000;
  font-weight: 800;
}

.row-color {
  background-color: var(--blueLow);
  z-index: -1;
}

.autocomplete-ul {
  background-color: white;
  max-height: 400px;
  overflow: scroll;
  overflow-x: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: relative;
}

.autocomplete-li {
  cursor: pointer;
  border-top: none;
  position: absolute;
}

.autocomplete-li:last-child {
  border-bottom: none;
}

.autocomplete-li:hover {
  background-color: whitesmoke;
}

.MuiDataGrid-cell[data-field="separator"] {
  width: 5px !important;
  min-width: 5px !important;
}
.MuiDataGrid-columnHeader[data-field="separator"] {
  width: 5px !important;
  min-width: 5px !important;
}
