.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  background-color: #2c3548;
  padding: 2rem;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 500px;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.modalTitle {
  font-size: 1.5rem;
  color: #ffffff;
}

.closeButton {
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #4a5568;
  border-radius: 0.25rem;
  background-color: #1e2537;
  color: #ffffff;
}

.input::placeholder {
  color: #a0aec0;
}

.fileUpload {
  margin-bottom: 1rem;
}

.fileUploadLabel {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: #4a5568;
  color: white;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.fileUploadLabel:hover {
  background-color: #2d3748;
}

.fileInput {
  display: none;
}

.fileList {
  margin-top: 1rem;
  color: #ffffff;
}

.fileList ul {
  list-style-type: none;
  padding-left: 1rem;
}

.fileList li {
  margin-bottom: 0.25rem;
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.cancelButton,
.submitButton {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.cancelButton {
  background-color: #4a5568;
  color: #ffffff;
}

.cancelButton:hover {
  background-color: #2d3748;
}

.submitButton {
  background-color: #4299e1;
  color: #ffffff;
}

.submitButton:hover {
  background-color: #3182ce;
}