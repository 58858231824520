.container {
  min-height: 100vh;
  background-color: #3f4b6c;
  padding: 2rem;
}

.content {
  max-width: 100%;
  margin: 0 auto;
}

.title {
  font-size: 1.875rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 2rem;
}

.boardContainer {
  display: flex;
  overflow-x: auto;
  padding-bottom: 1rem;
}

.addColumnButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 1rem;
  height: fit-content;
  margin-left: 1rem;
}

.addColumnButton:hover {
  background-color: rgba(255, 255, 255, 0.3);
}