.container {
  border: solid;
  border-width: 1.5px;
  border-color: var(--blueFour);
  width: 100%;
  padding: 10px 20px;
  border-radius: 8px;
  background-color: #fcfaff;
}

.createNewThread {
  padding: 13px 0;
  font-weight: 500;
  cursor: pointer;
}

.createNewThreadContainer {
  padding: 0 25px;
  margin: 20px auto;
  max-width: 900px;
}

.topContainer {
  padding: 20px 20px 6px 24px;
}

.id {
  font-size: 24px;
}

.title {
  width: 100%;
  margin-bottom: 20px;
}

.textfield {
  width: 100%;
}

.threadsContainer {
  max-width: 900px;
  margin: 0 auto;
  padding: 0 25px;
}

.cardWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 8px;
}

.card {
  /* margin: 30px 0; */
  border: solid;
  border-width: 1.5px;
  border-radius: 8px;
  background-color: #fcfaff;
  border-color: #dee2ff;
}

.user {
  display: flex;
  align-items: center;
}

.userImg {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  margin-top: 16px;
}

.userImgComment {
  width: 36px;
  height: 36px;
  margin-right: 8px;
  margin-top: 14px;
  border-radius: 40px;
}

.username {
  font-size: 16px;
  margin-left: 8px;
  font-weight: 400;
  color: var(--blackLow);
}

.dateWrap {
  display: flex;
  align-items: center;
}

.threadTitle {
  font-size: 20px;
  font-weight: 500;
}

.dateTitle {
  font-size: 14px;
  color: var(--gray);
}

.uploadLabel {
  display: flex;
  align-items: center;
  cursor: pointer;
  text-transform: none;
  font-size: 16px;
  color: var(--grayDark);
  padding: 0;
}

.uploadIcon {
  margin-right: 6px;
  color: var(--grayDark);
}

.publish {
  background-color: var(--blueFour);
  color: #fcfaff;
  text-transform: none;
  padding: 4px 8px;
  min-width: 80px;
  height: 35px;
  font-size: 16px;
}

.publish:hover {
  background-color: var(--blue);
}

.content {
  width: 100%;
}

.threadClick {
  display: flex;
  align-items: center;
  margin-right: 25px;
}

.arrowBack {
  transform: rotate(-90deg);
  margin-top: -6px;
  cursor: pointer;
  margin-right: 4px;
}

.arrowForward {
  cursor: pointer;
  color: #240046;
  margin-right: 4px;
}

.displayButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.submit {
  background-color: #240046;
  color: white;
}

.submit:hover {
  background-color: #240046;
  color: white;
}

.closeOnFile {
  z-index: 1500;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  text-transform: none;
  color: var(--blueTwo);
  margin-bottom: 20px;
}

.transpGradientClose {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, transparent, #fcfaff 70%);
  z-index: 1000;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 20px;
}

.transpGradientOpen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.leerMas {
  z-index: 1500;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  text-transform: none;
  color: var(--blueTwo);
}

.verMas {
  z-index: 1500;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  text-transform: none;
  color: var(--blueTwo);
  margin-top: 10px;
  background-color: #fcfaff;
}
.downloadIcon {
  margin-left: 14px;
  cursor: pointer;
}

.cerrarLeerMas {
  transform: rotate(-270deg);
  margin-top: -5px;
  cursor: pointer;
  margin-left: 4px;
  width: 14px;
  color: var(--blueTwo);
}

.leerMas {
  transform: rotate(-90deg);
  margin-top: -5px;
  cursor: pointer;
  margin-left: 4px;
  width: 14px;
  color: var(--blueTwo);
}

.cerrar {
  transform: rotate(-270deg);
  margin-top: 8px;
  cursor: pointer;
  margin-left: 4px;
  width: 14px;
  color: var(--blueTwo);
}
.imgFile {
  position: absolute;
  object-fit: cover;
  display: flex;
  max-width: 100%;
  max-height: 600px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding-right: 16px;
  cursor: pointer;
}

.imgFileThread {
  position: absolute;
  object-fit: cover;
  display: flex;
  max-width: 100%;
  max-height: 600px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding-right: 16px;
  cursor: pointer;
}
.imageclick {
  z-index: 1001;
}
.divisionLine {
  display: flex;
  height: 50px;
  width: 1.2px;
  background-color: #dee2ff;
}

.divisionLineComment {
  display: flex;
  height: 25px;
  width: 1.2px;
  background-color: #dee2ff;
}

.divisionContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.responderButton {
  background-color: transparent;
  text-transform: capitalize;
  font-size: 16px;
  padding: 0 12px;
  width: 100%;
  height: 100%;
  font-weight: 400;
}

.mentionContainer {
  margin: 10px;
  padding: 20px 5px;
  border-radius: 8px;
}

.commentFile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.uploadFile {
  background-color: #240046;
  color: white;
}

.uploadFile:hover {
  background-color: #240046;
  color: white;
}

.commentContainer {
  border-radius: 15px;
  margin: 10px 0;
}

.commentContent {
  background-color: #f2f4f8;
  border-radius: 8px;
  overflow-wrap: break-word;
  position: relative;
}
.commentOutside {
  margin: 14px 15px 7px;
}

.commentOutside:last-child {
  margin: 14px 15px 14px;
}

.commentWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  width: fit-content;
}

.commentText {
  width: fit-content;
}

.usernameComment {
  font-weight: 500;
  font-size: 16px;
  color: var(--blue);
}

.transpGradientCloseComment {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, transparent, var(--blueLow) 70%);
  z-index: 1000;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.noConversation {
  margin: 20px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.commentBottomWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px 0 0;
}

.dateComment {
  font-size: 14px;
  color: var(--gray);
}

.commentResponse {
  display: flex;
  justify-content: center;
  align-items: center;
}
