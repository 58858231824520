.container {
    width: 100%;
    max-width: 24rem;
    background-color: #1f2937;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    padding: 1.5rem;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.title {
    font-size: 1.25rem;
    font-weight: bold;
    color: #f3f4f6;
}

.buttonGroup {
    display: flex;
    align-items: center;
}

.iconButton {
    padding: 0.5rem;
    color: #9ca3af;
    cursor: pointer;
}

.iconButton:hover {
    color: #e5e7eb;
}

.progressContainer {
    margin-bottom: 1rem;
}

.progressBar {
    width: 100%;
    background-color: #4b5563;
    border-radius: 9999px;
    height: 0.625rem;
}

.progressFill {
    background-color: #3b82f6;
    height: 100%;
    border-radius: 9999px;
    transition: width 300ms ease-in-out;
}

.progressText {
    font-size: 0.875rem;
    color: #9ca3af;
    margin-top: 0.25rem;
}

.list {
    list-style-type: none;
    padding: 0;
    margin: 0 0 1rem 0;
}

.listItem {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

.checkbox {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    border: 1px solid #4b5563;
    border-radius: 0.25rem;
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.checkbox.checked {
    background-color: #3b82f6;
    border-color: #3b82f6;
}

.itemText {
    flex-grow: 1;
    color: #d1d5db;
}

.itemText.checked {
    text-decoration: line-through;
    color: #6b7280;
}

.removeButton {
    flex-shrink: 0;
    padding: 0.25rem;
    color: #6b7280;
    cursor: pointer;
}

.removeButton:hover {
    color: #9ca3af;
}

.inputContainer {
    display: flex;
    align-items: center;
}

.input {
    flex-grow: 1;
    padding: 0.5rem 0.75rem;
    background-color: #374151;
    color: #e5e7eb;
    border: 1px solid #4b5563;
    border-radius: 0.375rem 0 0 0.375rem;
}

.input:focus {
    outline: none;
    ring: 2px solid #3b82f6;
}

.input::placeholder {
    color: #6b7280;
}

.addButton {
    padding: 0.5rem 1rem;
    background-color: #3b82f6;
    color: #f3f4f6;
    border: none;
    border-radius: 0 0.375rem 0.375rem 0;
    cursor: pointer;
}

.addButton:hover {
    background-color: #2563eb;
}

.addButton:focus {
    outline: none;
    ring: 2px solid #3b82f6;
}