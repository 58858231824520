.closeContainer {
  display: flex;
  justify-content: flex-end;
  margin: 0px 0px 10px;
}

.titleContainer {
  padding: 0px 20px 20px;
  font-size: 16px;
}

.affiliateName {
  font-weight: bold;
  margin-bottom: 20px;
  padding: 20px 20px 0px;
  font-size: 16px;
}

.modalContainer {
  background-color: var(--blueLow);
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #dee2ff;
}

.textFieldContainer {
  width: 100%;
  margin: 20px 0px;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
