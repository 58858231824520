.card {
  background-color: #3a4458;
  padding: 0.75rem;
  border-radius: 0.375rem;
  cursor: move;
  transition: background-color 0.2s;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.card:hover {
  background-color: #4a5568;
}

.taskTitle {
  font-weight: 600;
  font-size: 0.875rem;
  color: #ffffff;
  margin: 0;
}

.taskMeta {
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  color: #a0aec0;
}

.taskId {
  font-weight: 600;
}

.taskDate {
  display: flex;
  align-items: center;
}

.taskOwner {
  font-size: 0.75rem;
  color: #a0aec0;
  margin: 0;
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 0.25rem;
}

.tagList {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
}

.tag {
  background-color: #7d9dd3;
  color: #ffffff;
  font-size: 0.625rem;
  padding: 0.125rem 0.25rem;
  border-radius: 0.25rem;
}

.cardFooter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.25rem;
  color: #a0aec0;
}

.conversationIcon {
  color: #a0aec0;
}
.taskDescription {
  font-size: 0.85rem;
  color: #a0aec0;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
