.column {
  background-color: #2c3548;
  padding: 0.5rem;
  border-radius: 0.5rem;
  width: 17rem;
  display: flex;
  flex-direction: column;
}

.columnTitle {
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #ffffff;
}

.taskList {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.addTaskButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  background-color: transparent;
  color: #a0aec0;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
}

.addTaskButton:hover {
  background-color: #3a4458;
  color: #ffffff;
}