.cardContent {
  padding: 0;
}

.lineFlex {
  display: flex;
  align-items: center;
}

.wrapperForm {
  width: 60%;
  margin-left: 425px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wrapperCap {
  width: 720px;
  background-color: white;
  padding: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.stepperContainer {
  width: 70vw;
  padding: 16px;
}

th {
  border-right: 1px solid rgba(224, 224, 224, 1);
  font-weight: 600;
  color: white;
}

tr {
  border: 1px solid rgba(224, 224, 224, 1);
}
td {
  border: 1px solid rgba(224, 224, 224, 1);
}

.tableCell {
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
}
.uploadForm {
  height: 400px;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  z-index: 100;
  border: 5px dotted #784af4;
}

.input {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.logoContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 5px;
  width: 100%;
  height: 400px;
  background-color: var(--blueLow);
  padding: 20px;
}
.logo {
  max-width: 400px;
  max-height: 400px;
  object-fit: contain;
  padding: 20px;
}

.logoTitle {
  display: flex;
  align-items: center;
}

.deleteIcon {
  font-size: 26px;
}
