.globalContainer {
  background-color: #f8f7ff;
  min-height: 92vh;
  padding: 10px 30px;
  overflow: hidden;
}

.tabContainer {
  background-color: #f8f7ff;
  padding: 0px 30px;
}

.homeContainer {
  background-color: #f8f7ff;
  min-height: 92vh;
  padding: 12%;
  display: flex;

  flex-direction: row;
  justify-content: center;
  align-content: center;
}

.homeLoginButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;
  padding-right: 20px;
}

.homeImage {
  position: absolute;
  right: 28%;
  top: 35%;
}

.campaignCreatorContainer {
  background-color: #f8f7ff;
  min-height: 91.7vh;
  padding: 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
