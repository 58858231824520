.paragraph {
  cursor: pointer;
  font-weight: 500;
  padding: 6px 20px;
}

.paragraph:first-child {
  margin-left: 0;
  padding-left: 0px;
}

.globalContainer {
  display: flex;
}

.selected {
  border-bottom: 3px solid var(--blue);
}

.principal {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .globalContainer {
    overflow-x: scroll;
  }

  .paragraph {
    width: 100%;
    text-align: center;
    font-size: 14px;
    display: flex;
  }
}
