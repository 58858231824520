.modalOverlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 5rem;
  z-index: 50;
}

.modalContent {
  background-color: #2c3548;
  padding: 1rem;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 20rem;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.input {
  width: 100%;
  padding: 0.5rem;
  border: 2px solid #3a4458;
  border-radius: 0.375rem;
  background-color: #1e2537;
  color: #ffffff;
}

.input::placeholder {
  color: #8b95a9;
}

.buttonGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.submitButton {
  padding: 0.5rem 1rem;
  background-color: #5aa162;
  color: white;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submitButton:hover {
  background-color: #4c8653;
}

.closeButton {
  background: none;
  border: none;
  color: #8b95a9;
  cursor: pointer;
  padding: 0.25rem;
}

.closeButton:hover {
  color: #ffffff;
}