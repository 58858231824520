.closeContainer {
  display: flex;
  justify-content: flex-end;
  margin: 0px 0px 10px;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  background-color: "var(--blueLow)";
  padding: 20px;
  margin: 0px 0px 20px;
  border: 1px solid #dee2ff;
}

.deleteTag {
  display: flex;
  justify-content: flex-end;
  margin: 0px 0px 10px;
  width: 100%;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
