.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  background-color: #2c3548;
  padding: 2rem;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.modalTitle {
  font-size: 1.5rem;
  color: #ffffff;
}

.closeButton {
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.formGroup {
  display: flex;
  flex-direction: column;
}

.formGroup label {
  margin-bottom: 0.5rem;
  color: #ffffff;
}

.input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #4a5568;
  border-radius: 0.25rem;
  background-color: #1e2537;
  color: #ffffff;
}

.input::placeholder {
  color: #a0aec0;
}

.metaInfo {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
}

.metaItem {
  display: flex;
  align-items: center;
  color: #a0aec0;
}

.icon {
  margin-right: 0.5rem;
}

.attachmentSection {
  margin-bottom: 1rem;
}

.attachmentLabel {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  color: #ffffff;
}

.fileInput {
  margin-bottom: 0.5rem;
}

.attachmentList {
  list-style-type: none;
  padding-left: 1rem;
  color: #a0aec0;
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.deleteButton,
.saveButton {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.deleteButton {
  background-color: #e53e3e;
  color: #ffffff;
}

.deleteButton:hover {
  background-color: #c53030;
}

.saveButton {
  background-color: #4299e1;
  color: #ffffff;
}

.saveButton:hover {
  background-color: #3182ce;
}