.wrapper {
  width: 100%;
}

.textfields {
  margin: 10px;
  width: 250px;
}

.dobleTextfields {
  margin: 10px;
  width: 386px;
}

.description {
  margin: 10px;
  width: 790px;
}

.flex {
  display: flex;
}

.marginLeft10px {
  margin-left: 10px;
}

.stepperContainer {
  min-width: 1000px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  overflow-y: scroll;
}

.submitContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 9999;
  position: fixed;
  bottom: 0%;
  right: 0%;
  left: 0%;
  background-color: var(--grayTwo);
  padding: 10px 60px;
}
